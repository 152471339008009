import Utility from "../../../shared/utility.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import utilityService from "./utility-service";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      subPageUrl: "",
      backSlash: true,
      backSlashParent: true,
      disableProject:false,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      selectedProject: "",
      projectData: [],
      searchOrderVal: "",
      selectedBusiness: "",
      businessData: [],
      searchOrderedPartNum: "",
      totalRecords: 0,
      allocationData: [],
      allocationDataTemp: [],
      filterArray: [],
      exportDisable: true,
      excelName: "",
      toggleAllocationDialog: false,
      orderNumber: "",
      orderStatus: "",
      createdDate: "",
      custOrderIds: "",
      eligibilityStatus: "",
      orderedDate: "",
      orderPartData: [],
      allocEligibleData: [],
      allocBlockedStatus: false,
      projectNotNull: true,
      showAllocationDet: false,
      searchAllocations: "",
      filterOrderType: "",
      filterStatus: "",
      filterOrderStatus: "",
      allocationSOKey: 0,
      allocationConfirmDialog: false,
      refreshArray: [],
      allocationHeaders: [
        { text: "Allocation Allowed", align: "start", value: "blocked", class: "primary customwhite--text", width: "6%" },
        { text: "Project/Business", value: "project", class: "primary customwhite--text", width: "20%" },
        { text: "Order#", value: "sono", class: "primary customwhite--text", width: "10%" },
        { text: "Customer ID", value: "customer_id", class: "primary customwhite--text", width: "6%" },
        { text: "Order Type", value: "order_type", class: "primary customwhite--text", width: "8%" },
        { text: "Status", value: "order_status", class: "primary customwhite--text", width: "10%" },
        { text: "Date", value: "ordered_date", class: "primary customwhite--text", width: "18%" },
        { text: "Total Order Qty", value: "order_qty", class: "primary customwhite--text", width: "6%" },
        { text: "View Ordered Parts", value: "View", class: "primary customwhite--text", width: "10%" },
        { text: "Action", value: "Action", class: "primary customwhite--text", width: "4%" },
      ],
      orderPartHeaders: [
        { text: "Part Number", align: "start", value: "PartNumber", class: "primary customwhite--text" },
        { text: "Warehouse", value: "Warehouse", class: "primary customwhite--text" },
        { text: "Ordered", value: "Ordered", class: "primary customwhite--text" },
        { text: "Available", value: "Available", class: "primary customwhite--text" },
      ],
      allocEligibleHeaders: [
        { text: "Part Number", align: "start", value: "PartNumber", class: "primary customwhite--text" },
        { text: "Warehouse", value: "Warehouse", class: "primary customwhite--text" },
        { text: "Ordered", value: "Ordered", class: "primary customwhite--text" },
        { text: "Available", value: "Available", class: "primary customwhite--text" },
      ],
      json_fields: {
        "Allocation Allowed": "blocked",
        Project: "project",
        "Order#": "sono",
        "Customer ID": "customer_id",
        "Order Type": "order_type",
        Status: "order_status",
        Date: "ordered_date",
        "Total Order Qty": "order_qty",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProjectData();
  },
  computed: {
    customerOrderIds() {
      return this.custOrderIds.replace(/\|\|/g, "<br />");
    },
  },
  mounted() {},
  methods: {
    //Reset Function
    resetFunction() {
      this.disableProject=false,
      this.selectedProject = "";
      this.projectData = [];
      this.searchOrderVal = "";
      this.selectedBusiness = "";
      this.businessData = [];
      this.searchOrderedPartNum = "";
      this.totalRecords = 0;
      this.exportDisable = true;
      this.excelName = "";
      this.toggleAllocationDialog = false;
      this.projectNotNull = true;
      this.allocationData = [];
      this.allocationDataTemp = [];
      this.showAllocationDet = false;
      this.searchAllocations = "";
      this.filterOrderType = "";
      this.filterStatus = "";
      this.filterOrderStatus = "";
      this.allocationConfirmDialog = false;
      this.refreshArray = [];
      this.getProjectData();
    },
    //Clear Search
    clearSearch() {
      this.disableProject=false,
      this.selectedProject = "";
      this.projectData = [];
      this.searchOrderVal = "";
      this.selectedBusiness = "";
      this.businessData = [];
      this.searchOrderedPartNum = "";
      this.totalRecords = 0;
      this.exportDisable = true;
      this.excelName = "";
      this.toggleAllocationDialog = false;
      this.projectNotNull = true;
      this.allocationData = [];
      this.allocationDataTemp = [];
      this.filterOrderType = "";
      this.filterStatus = "";
      this.filterOrderStatus = "";
      this.getProjectData();
    },
    //Get project data
    async getProjectData() {
      let data = await utilityService.getProjectData("get", this.userId);
      if (data && data.message !== "NA") {
        this.projectData = data;
        this.disableProject=false;
      }
      else{
        this.disableProject=true;
      }
    
    },
    //On Project selected
    async onProjectSelect() {
      let data = await utilityService.getBusinessData("get", this.userId, this.selectedProject);
      if (data.message !== "NA") {
        this.businessData = data;
        this.projectNotNull = false;
      }
    },
    //On Search click button
    async onSearchClick(search) {
      if (search == "refresh") {
        let showMessage = false;
        let searchData = await utilityService.searchAllocation("post", this.refreshArray, showMessage);
        if (searchData.message !== "NA") {
          this.totalRecords = searchData.length;
          const newArr = searchData.map((obj) => {
            return {
              ...obj,
              ordered_date: Utility.convertESTToLocal(obj.ordered_date),
            };
          });
          this.allocationData = newArr;
          this.allocationDataTemp = newArr;
          this.filterArray = this.distinctValues();
          this.exportDisable = false;
          this.filterOrderType = "";
          this.filterStatus = "";
          this.filterOrderStatus = "";
        } else {
          this.totalRecords = 0;
          this.allocationData = [];
          this.allocationDataTemp = [];
          this.exportDisable = true;
        }
      } else {
        let obj = {
          userid: parseInt(this.userId),
          proj_key: parseInt(this.selectedProject == "" || this.selectedProject == null ? 0 : this.selectedProject),
          bu_key: parseInt(this.selectedBusiness == "" || this.selectedBusiness == null ? 0 : this.selectedBusiness),
          partStr: this.searchOrderedPartNum,
          orderStr: this.searchOrderVal,
        };
        this.refreshArray = [];
        this.refreshArray = obj;
        let showMessage = false;
        let searchData = await utilityService.searchAllocation("post", obj, showMessage);
        if (searchData.message !== "NA") {
          this.totalRecords = searchData.length;
          const newArr = searchData.map((obj) => {
            return {
              ...obj,
              ordered_date: Utility.convertESTToLocal(obj.ordered_date),
            };
          });
          this.allocationData = newArr;
          this.allocationDataTemp = newArr;
          this.filterArray = this.distinctValues();
          this.exportDisable = false;
          this.filterOrderType = "";
          this.filterStatus = "";
          this.filterOrderStatus = "";
        } else {
          this.totalRecords = 0;
          this.allocationData = [];
          this.allocationDataTemp = [];
          this.exportDisable = true;
        }
      }
    },
    //View Allocation Details
    async viewAllocation(soKey) {
      this.orderPartData = [];
      let tooltip = document.getElementById("tooltiptable");
      if (tooltip != null && tooltip != undefined) {
        document.getElementById("tooltiptable").classList.remove("tooltiptext");
      }
      this.axios
        .get("ut/allocation_orderparts?userid=" + this.userId + "&so_key=" + soKey)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.orderPartData = responseData;
            document.getElementById("tooltiptable").innerHTML = "";
            var html_table =
              '<table style="border-collapse: collapse;width: 100%;"><tr class="trClass"><th class="trClass">Part Number</th><th class="trClass">Warehouse</th><th class="trClass">Ordered</th><th class="trClass">Available</th></tr>';
            this.orderPartData?.forEach((element) => {
              html_table =
                html_table +
                "<tr><td>" +
                element.PartNumber +
                "</td><td>" +
                element.Warehouse +
                "</td><td>" +
                element.Ordered +
                "</td><td>" +
                element.Available +
                "</td></tr>";
            });
            document.getElementById("tooltiptable").innerHTML = html_table;
            document.getElementById("tooltiptable").classList.add("tooltiptext");
            document.getElementById("tooltiptable").style.visibility = "visible";
          } else {
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    fadeToolTip() {
      let tooltip = document.getElementById("tooltiptable");
      if (tooltip != null && tooltip != undefined) {
        document.getElementById("tooltiptable").style.visibility = "hidden";
      }
    },
    //Allocation Data
    async detailsAllocation(item) {
      let data = await utilityService.getAllocationData("get", this.userId, item.so_id);
      if (data.message !== "NA") {
        this.orderNumber = data[0].order_number;
        this.orderStatus = data[0].order_status;
        this.createdDate = Utility.convertESTToLocal(data[0].create_date);
        this.custOrderIds = data[0].customer_id;
        this.eligibilityStatus = data[0].eligibility_status;
        this.orderedDate = Utility.convertESTToLocal(data[0].ordered_date);
        this.allocEligibleData = data[0].parts;
        this.allocBlockedStatus = data[0].blocked;
        this.allocationSOKey = data[0].order_id;
        this.toggleAllocationDialog = true;
      } else {
        this.orderNumber = "";
        this.orderStatus = "";
        this.createdDate = "";
        this.custOrderIds = "";
        this.eligibilityStatus = "";
        this.orderedDate = "";
        this.allocEligibleData = "";
        this.allocBlockedStatus = false;
        this.allocationSOKey = 0;
        this.toggleAllocationDialog = false;
      }
    },
    //Cancel button on popup
    onCancel() {
      this.orderNumber = "";
      this.orderStatus = "";
      this.createdDate = "";
      this.custOrderIds = "";
      this.eligibilityStatus = "";
      this.orderedDate = "";
      this.allocEligibleData = "";
      this.allocBlockedStatus = false;
      this.allocationSOKey = 0;
      this.allocEligibleData = [];
      this.toggleAllocationDialog = false;
    },
    //File Name
    fileName() {
      this.excelName =
        "Manual_Allocation_List_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    //Removing || from customer id's in data table
    formattedText(customerId) {
      return customerId.replace(/\|\|/g, "<br />");
    },
    //Getting distinct values for filters
    distinctValues() {
      const properties = ["order_type", "order_status", "blocked"];
      return properties.reduce((acc, prop) => {
        acc[prop] = [...new Set(this.allocationData.map((order) => order[prop]))];
        return acc;
      }, {});
    },
    //On Filter Change
    filterSelected(name, id) {
      this.filterOrderType = "";
      this.filterStatus = "";
      this.filterOrderStatus = "";
      switch (id) {
        case 1: {
          this.filterOrderType = name;
          let orderTypeFilterArray = this.allocationDataTemp.filter((element) => element.order_type == name);
          this.filterValueUpdate(orderTypeFilterArray);
          break;
        }
        case 2: {
          this.filterStatus = name;
          let statusFilterArray = this.allocationDataTemp.filter((element) => element.blocked == name);
          this.filterValueUpdate(statusFilterArray);
          break;
        }
        case 3: {
          this.filterOrderStatus = name;
          let orderStatusFilterArray = this.allocationDataTemp.filter((element) => element.order_status == name);
          this.filterValueUpdate(orderStatusFilterArray);
          break;
        }
      }
    },
    //Updating Data Table array
    filterValueUpdate(data) {
      this.totalRecords = data.length;
      this.allocationData = data;
    },
    //Allocation status change
    changeAllocationStatus() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let toggleModel = {
        userid: parseInt(this.userId),
        so_key: parseInt(this.allocationSOKey),
      };
      this.axios
        .post("ut/allocation_toggle", toggleModel)
        .then((response) => {
          if (response.status == 200) {
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.allocationConfirmDialog = false;
            this.toggleAllocationDialog = false;
            this.onSearchClick();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.allocationConfirmDialog = false;
          this.toggleAllocationDialog = false;
          this.onSearchClick();
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
  },
};
